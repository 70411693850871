import React from 'react';
import { Link } from 'react-router-dom';
import { formatRut, addMoneyWithCurrency } from '@fingo/lib/helpers';
import { useIsMobile } from '@fingo/lib/hooks';
import { ActionDrawerCell, ActionCell } from '@fingo/lib/components/cells';
import { Grid, Typography, Link as MuiLink } from '@mui/material';
import { Stack } from '@mui/system';
import {
  DateWithDaysDiffCell,
  TotalAmountCell,
} from '@fingo/lib/components/dataGridCells';
import DrawerHeader from '@fingo/lib/components/drawer/DrawerHeader';
import CollectionActions from '@fingo/lib/components/drawer/CollectionActions';
import dayjs from '@fingo/lib/config/dayjs';
import { filterByUniqueObjectsByKey } from '@fingo/lib/helpers/arrays';
import CompanyBlacklistComponent from '@fingo/lib/components/cells/CompanyBlacklistComponent';
import ProgressBarCell from '../../../components/dataGridCells/ProgressBarCell';

const useCollectionDebtorColumns = (documentLink) => {
  const isMobile = useIsMobile();
  return [
    {
      field: 'name',
      headerName: 'Deudor',
      type: 'string',
      minWidth: 210,
      sortable: true,
      filterable: false,
      flex: 1,
      renderCell: ({ row }) => (
        <Stack alignItems="flex-start" justifyContent="center">
          <Stack direction="row">
            <MuiLink
              component={Link}
              to={`/app/${documentLink}-debtor/${row.id}`}
              color="primary"
              variant="body2"
              align="left"
              rel="noopener noreferrer"
              underline="hover"
            >
              {row.name}
            </MuiLink>
            <CompanyBlacklistComponent masterEntity={row} />
          </Stack>
          {!isMobile && (
            <Typography variant="subtitle1">
              {row.displayNationalIdentifier}
            </Typography>
          )}
        </Stack>
      ),
    },
    {
      field: 'documentsCount',
      headerName: 'Docs',
      type: 'string',
      maxWidth: 60,
      sortable: false,
      filterable: false,
      flex: 1,
      valueGetter: (params) => params.row.pendingCollectionManagers.length,
      renderCell: (params) => (
        <Grid container alignItems="center" justifyContent="center">
          <Typography variant="body2">{params.value}</Typography>
        </Grid>
      ),
    },
    {
      field: 'minDateToPay',
      headerName: 'Menor vencimiento',
      type: 'string',
      minWidth: 140,
      sortable: false,
      filterable: false,
      flex: 1,
      valueGetter: (params) => {
        const orderedMngrs = [...params.row.pendingCollectionManagers].sort(
          (mngr1, mngr2) => dayjs(mngr1.collectedObjectDateToPay)
            - dayjs(mngr2.collectedObjectDateToPay),
        );
        return orderedMngrs[0];
      },
      renderCell: (params) => (
        <DateWithDaysDiffCell
          date={params.value.collectedObjectDateToPay}
          useColors
        />
      ),
    },
    {
      field: 'totalAmount',
      headerName: 'Total en cobranza',
      type: 'string',
      sortable: false,
      filterable: false,
      minWidth: 120,
      flex: 1,
      renderCell: (params) => (
        <TotalAmountCell
          moneyFields={params.row.pendingCollectionManagers.map(
            (manager) => manager.collectedObjectAmount,
          )}
        />
      ),
    },
    {
      field: 'expiredAmount',
      headerName: 'Vencido',
      type: 'string',
      headerAlign: 'center',
      sortable: false,
      filterable: false,
      maxWidth: 160,
      flex: 1,
      valueGetter: (params) => {
        const managers = params.row.pendingCollectionManagers;
        const totalAmount = managers.reduce(
          (prev, curr) => addMoneyWithCurrency(prev, curr.collectedObjectAmount),
          0,
        );
        const expiredAmount = managers
          .filter(
            ({ collectedObjectDateToPay }) => collectedObjectDateToPay
              && dayjs(collectedObjectDateToPay) < dayjs(),
          )
          .reduce(
            (prev, curr) => addMoneyWithCurrency(prev, curr.collectedObjectAmount),
            0,
          );
        return expiredAmount.amount / totalAmount.amount;
      },
      renderCell: (params) => (
        <ProgressBarCell value={params.value ? params.value * 100 : 0} />
      ),
    },
    {
      field: 'lastAction',
      headerName: 'Última gestión',
      type: 'string',
      sortable: false,
      filterable: false,
      minWidth: 130,
      flex: 1,
      valueGetter: (params) => {
        const actions = params.row.pendingCollectionManagers
          .map((mgr) => mgr.actions)
          .flat();
        const newAc = [...actions].sort((a, b) => b.id - a.id);
        return newAc[0];
      },
      renderCell: (params) => <ActionCell action={params.value} />,
    },
    {
      field: 'actionsDrawer',
      headerName: 'Gestiones',
      type: 'string',
      sortable: false,
      filterable: false,
      maxWidth: 90,
      flex: 1,
      valueGetter: (params) => {
        const actions = params.row.pendingCollectionManagers
          .map((mgr) => mgr.actions)
          .flat();
        const uniqueActions = filterByUniqueObjectsByKey(actions, 'id');
        return uniqueActions.sort((a, b) => b.id - a.id);
      },
      renderCell: (params) => (
        <ActionDrawerCell
          title={params.row.name}
          subtitle={formatRut(params.row.rut)}
          actions={params.value}
          headerComponent={DrawerHeader}
          contentComponent={CollectionActions}
        />
      ),
    },
  ];
};

export default useCollectionDebtorColumns;
