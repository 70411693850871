import React from 'react';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import { FingoDatePicker } from '@fingo/lib/components/datePickers';
import PropTypes from 'prop-types';
import dayjs from '@fingo/lib/config/dayjs';
import Delete from '@mui/icons-material/Delete';
import remove from 'lodash/remove';
import { MoneyInput } from '@fingo/lib/components/inputs';
import { formatMoneyWithSign } from '@fingo/lib/helpers';

const InstallmentRow = ({
  index,
  amortizationToPay,
  paymentDate,
  _setInstallments,
  interestAmount,
  totalAmount,
  setRecalculateInstallments,
}) => {
  const setInstallment = (id, installment) => {
    _setInstallments((old) => {
      const listCopy = [...old];
      listCopy[id] = installment;
      return listCopy;
    });
  };
  const changeInstallmentValue = (field, newValue) => {
    const newInstallment = {
      amortizationToPay,
      paymentDate,
    };
    newInstallment[field] = newValue;
    setInstallment(index, newInstallment);
    setRecalculateInstallments((value) => !value);
  };
  const deleteInstallment = () => {
    _setInstallments((old) => {
      const newList = [...old];
      remove(newList, (_, postition) => postition === index);
      return newList;
    });
    setRecalculateInstallments((old) => !old);
  };
  return (
    <Stack direction="row" alignItems="center" spacing={2}>
      <Typography width="120px" variant="subtitle2">
        {index ? `Cuota N°${index}` : 'Upfront'}
      </Typography>
      <FingoDatePicker
        value={paymentDate}
        onChange={(value) => changeInstallmentValue('paymentDate', dayjs(value).startOf('day'))}
        renderInput={(params) => (
          <TextField
            {...params}
            variant="standard"
            fullWidth
            size="small"
            sx={{ width: '180px' }}
            inputProps={{ ...params.inputProps, 'data-hj-allow': '' }}
          />
        )}
      />
      <MoneyInput
        size="small"
        value={amortizationToPay}
        sx={{ width: '180px' }}
        setValue={(value) => changeInstallmentValue('amortizationToPay', value)}
      />
      <Typography sx={{ width: '180px' }}>
        {formatMoneyWithSign(interestAmount)}
      </Typography>
      <Typography sx={{ width: '180px' }}>
        {formatMoneyWithSign(totalAmount)}
      </Typography>
      <Tooltip title="Eliminar cuota">
        <IconButton size="small" onClick={deleteInstallment}>
          <Delete />
        </IconButton>
      </Tooltip>
    </Stack>
  );
};

InstallmentRow.propTypes = {
  index: PropTypes.number.isRequired,
  amortizationToPay: PropTypes.number.isRequired,
  interestAmount: PropTypes.number.isRequired,
  totalAmount: PropTypes.number.isRequired,
  paymentDate: PropTypes.string.isRequired,
  setRecalculateInstallments: PropTypes.func.isRequired,
  _setInstallments: PropTypes.func.isRequired,
};

export default InstallmentRow;
