/* eslint-disable operator-linebreak */
import React from 'react';
import { ActionDrawerCell, AmountWithIvaCell, DatePickerCell } from '@fingo/lib/components/cells';
import {
  CollectionPriorityCell,
  DateWithDaysDiffCell,
  StatusSummaryStepper,
} from '@fingo/lib/components/dataGridCells';
import DrawerHeader from '@fingo/lib/components/drawer/DrawerHeader';
import UserActions from '@fingo/lib/components/drawer/UserActions';
import DateCell from '@fingo/lib/components/cells/DateCell';
import { GRID_DATE_COL_DEF } from '@mui/x-data-grid';
import LinkButton from '@fingo/lib/components/buttons/LinkButton';

export const COLLECTION_INVOICES_COLUMNS = [
  'folio',
  'dateIssued',
  'company_MasterEntity_Name',
  'amountWithIva',
  'dateToPay',
  'agreedDateToPay',
  'siiStatus',
  'collectionManagerRelation_CollectionPriority_Value',
  'collectionActions',
];

export const COLLECTION_INVOICES_INITIAL_ORDER_BY =
  'collectionManagerRelation_CollectionPriority_Value';

export const COLUMNS = [
  {
    field: 'folio',
    headerName: 'Folio',
    headerAlign: 'center',
    renderCell: ({ row }) => (
      <LinkButton
        to={{
          pathname: '/app/invoice',
          search: `?invoiceId=${row.id}`,
        }}
        tooltipTitle={row.folio.length > 10 ? row.folio : ''}
        label={row.folio}
      />
    ),
  },
  {
    field: 'dateIssued',
    type: 'date',
    headerName: 'Emisión',
    headerAlign: 'center',
    filterable: false,
    flex: 1,
    renderCell: ({ row }) => <DateCell date={row.dateIssued} />,
  },
  {
    field: 'amountWithIva',
    type: 'number',
    headerName: 'Monto',
    headerAlign: 'center',
    flex: 1,
    renderCell: ({ row }) => <AmountWithIvaCell row={row} />,
  },
  {
    field: 'dateToPay',
    headerName: 'Fecha de pago según factura',
    headerAlign: 'center',
    align: 'center',
    ...GRID_DATE_COL_DEF,
    filterable: false,
    sortable: false,
    resizable: false,
    minWidth: 250,
    flex: 1,
    renderEditCell: (params) => <DatePickerCell params={params} />,
    renderCell: (params) => <DatePickerCell params={params} />,
  },
  {
    field: 'agreedDateToPay',
    headerName: 'Fecha de pago confirmada por el deudor',
    headerAlign: 'center',
    sortable: false,
    filterable: false,
    minWidth: 260,
    valueGetter: ({ row }) => row.collectionManager?.currentDataForCollection?.dateToPay,
    renderCell: ({ value }) => <DateWithDaysDiffCell date={value} useColors />,
  },
  {
    field: 'siiStatus',
    type: 'singleSelect',
    headerName: 'Estado',
    headerAlign: 'center',
    align: 'center',
    sortable: false,
    filterable: false,
    minWidth: 150,
    renderCell: ({ row }) => <StatusSummaryStepper invoice={row} />,
  },
  {
    field: 'collectionManagerRelation_CollectionPriority_Value',
    headerName: 'Prioridad',
    valueGetter: ({ row }) => row.collectionManager.collectionPriority.value,
    renderCell: ({ row }) => (
      <CollectionPriorityCell priority={row.collectionManager.collectionPriority.value} />
    ),
  },
  {
    field: 'collectionActions',
    headerName: 'Gestiones',
    headerAlign: 'center',
    sortable: false,
    filterable: false,
    renderCell: ({ row }) => (
      <ActionDrawerCell
        title={`Gestiones de cobranza factura ${row.folio}`}
        actions={
          row.collectionManager
            ? row.collectionManager.actions.filter((action) => action.actionType !== 'OTHER')
            : []
        }
        headerComponent={DrawerHeader}
        contentComponent={UserActions}
        drawerId={row.folio}
      />
    ),
  },
];

export const ACTIONS_OPTIONS = [
  {
    label: 'Llamado telefónico al receptor del documento',
    value: 'PHONE_CALL_TO_RECEIVER',
    enabled: 'debtor',
  },
  { label: 'Mail al receptor', value: 'MAIL_TO_RECEIVER', enabled: 'debtor' },
  {
    label: 'Envío formulario de pago al receptor',
    value: 'RECEIVER_FORM_MAIL',
    enabled: 'debtor',
  },
  { label: 'Agregar gestión genérica', value: 'OTHER', enabled: 'always' },
];

export const DEBTOR_FACTORING_OPTIONS = ['Cartera vigente', 'Cartera histórica'];

export const DEBTOR_FACTORING_CURRENT_PORTFOLIO_HEADERS = [
  'folio',
  'dateIssued',
  'company_MasterEntity_Name',
  'amountToCollect',
  'surplusDebt',
  'dateToPay',
  'agreedDateToPay',
  'executiveAssigned',
  'siiStatus',
  'collectionManagerRelation_CollectionPriority_Value',
  'ratificationActions',
  'actionsDrawer',
];

export const DEBTOR_FACTORING_CURRENT_PORTFOLIO_INITIAL_ORDER_BY =
  'collectionManagerRelation_CollectionPriority_Value';

export const DEBTOR_FACTORING_HISTORIC_PORTFOLIO_HEADERS = [
  'folio',
  'dateIssued',
  'company_MasterEntity_Name',
  'amountWithIva',
  'surplusDebt',
  'dateToPay',
  'paymentDate',
  'executiveAssigned',
  'siiStatus',
  'ratificationActions',
  'actionsDrawer',
];

export const DEBTOR_FACTORING_HISTORIC_PORTFOLIO_INITIAL_ORDER_BY = 'paymentDate';
