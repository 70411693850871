import React, { useCallback, useEffect, useState } from 'react';
import { DocumentList } from '@fingo/lib/components/lists';
import { EXPORT_INVOICES, GET_MASTER_ENTITY, INVOICES_IN_COLLECTION } from '@fingo/lib/graphql';
import { useInvoicePreColumns, useInvoiceLucilaCustomColumns, FINGO_MASTER_ENTITY_ID } from '@fingo/lib/constants';
import ControlledSelectMenu from '@fingo/lib/components/menus/ControlledSelectMenu';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { useQuery } from '@apollo/client';
import Box from '@mui/material/Box';
import RoundedWhiteBox from '@fingo/lib/components/boxes/RoundedWhiteBox';
import {
  DEBTOR_FACTORING_CURRENT_PORTFOLIO_HEADERS,
  DEBTOR_FACTORING_CURRENT_PORTFOLIO_INITIAL_ORDER_BY,
  DEBTOR_FACTORING_HISTORIC_PORTFOLIO_HEADERS,
  DEBTOR_FACTORING_HISTORIC_PORTFOLIO_INITIAL_ORDER_BY,
  DEBTOR_FACTORING_OPTIONS,
} from '../../constants/debtor';
import DebtorFactoringSummary from './DebtorFactoringSummary';

const DebtorFactoring = () => {
  const { debtorId } = useParams();
  const { data } = useQuery(GET_MASTER_ENTITY, {
    variables: { masterEntityId: debtorId },
    skip: !debtorId,
  });
  const debtor = data?.getMasterEntity;
  const [selectedDocumentIds, setSelectedDocumentIds] = useState([]);
  const [updateTrigger, setUpdateTrigger] = useState(false);
  const [updateCustomVariables, setUpdateCustomVariables] = useState(true);
  const [portfolio, setPortfolio] = useState('Cartera vigente');
  const [currency, setCurrency] = useState('');

  const getRowId = useCallback(({ id }) => id, []);

  const initialOrderBy = portfolio === 'Cartera vigente'
    ? DEBTOR_FACTORING_CURRENT_PORTFOLIO_INITIAL_ORDER_BY
    : DEBTOR_FACTORING_HISTORIC_PORTFOLIO_INITIAL_ORDER_BY;

  const headers = portfolio === 'Cartera vigente'
    ? DEBTOR_FACTORING_CURRENT_PORTFOLIO_HEADERS
    : DEBTOR_FACTORING_HISTORIC_PORTFOLIO_HEADERS;

  useEffect(() => {
    setUpdateCustomVariables((prev) => !prev);
  }, [currency]);

  useEffect(() => {
    setUpdateCustomVariables((prev) => !prev);
  }, []);

  return (
    <Box height="100%" width="100%" p={2} backgroundColor="gray.A200">
      <RoundedWhiteBox height="100%" width="100%">
        <DocumentList
          showGoBack
          trackerId="COLLECTION_INVOICES"
          type="collection-debtor-profile"
          headerTitle={debtor?.name}
          queryDocument={INVOICES_IN_COLLECTION}
          skipQueryDocument={!debtorId}
          initialOrderBy={initialOrderBy}
          includeHeaders={headers}
          checkboxSelection={portfolio === 'Cartera vigente'}
          onSelectionModelChange={setSelectedDocumentIds}
          mobileHeaders={['folio', 'receiver_Name', 'cessionStatus']}
          allCompaniesOptions
          getRowId={getRowId}
          updateTrigger={updateTrigger}
          setUpdateTrigger={setUpdateTrigger}
          queryExport={EXPORT_INVOICES}
          showExportInvoice
          showFilters
          defaultFilterProps={{
            showValidSiiCredentialsFilter: false,
            showLightningFilter: false,
            showPreoffersFilters: false,
            showStatesFilter: false,
            showDatesFilter: false,
            showRefresh: true,
            showCompanyIssuerFilter: false,
            currency,
          }}
          customVariables={{
            inCollection: portfolio === 'Cartera vigente' ? FINGO_MASTER_ENTITY_ID : null,
            status: portfolio === 'Cartera vigente' ? ['Transfered', 'Debt'] : ['Finished', 'SurplusWithdrawn'],
            debtorId,
            dateIssued_Gte: null,
            dateIssued_Lte: null,
            receiver_Rut: null,
            receiverId: null,
            currency,
          }}
          customSummaryElement={(
            <DebtorFactoringSummary
              debtor={debtor}
              selectedDocumentIds={selectedDocumentIds}
              showActions={portfolio === 'Cartera vigente'}
              currency={currency}
            />
      )}
          flexEndButtons={() => (
            <ControlledSelectMenu
              options={DEBTOR_FACTORING_OPTIONS}
              value={portfolio}
              setValue={setPortfolio}
              onChange={() => {
                setUpdateCustomVariables(true);
                setSelectedDocumentIds([]);
              }}
              currency={currency}
              setCurrency={setCurrency}
              showCurrencyFilter
            />
          )}
          initialPageSize={100}
          selectionModel={selectedDocumentIds}
          preColumns={useInvoicePreColumns(useInvoiceLucilaCustomColumns())}
          updateCustomVariables={updateCustomVariables}
          setUpdateCustomVariables={setUpdateCustomVariables}
        />
      </RoundedWhiteBox>
    </Box>
  );
};

export default DebtorFactoring;
